<template>
  <div class="container tooltip-content" v-if="!dataOtpFlags.confirmOtpStatus">
    <div class="row">
      <!-- Левая колонка -->

      <div class="col-lg-6 col-sm-12 d-flex flex-column justify-content-center">
        <div class="discount-info-block">
          <p class="mb-1">Будь ласка, вкажіть <span class="red">ціну</span> за якою було <span class="red">продано поліс</span>, клієнту</p>
          <p class="mb-1"> Це необхідно для:</p>
          <ul class="mb-1">
            <li>Коректного розрахунку виплат КВ з урахуванням знижки.</li>
            <li>Відображення реальної ціни поліса в аналітиці.</li>
            <li>Формування звітів та документів з актуальними фінансовими даними.</li>
          </ul>
        </div>
      </div>

      <div class="col-lg-6 col-sm-12 flex-column align-items-center">
        <div class="form-group flex-column w-100">
          <input :disabled="dataOtpFlags.confirmOtpStatus" v-model="paySum" type="text" placeholder="сумма" :class="['form-control', {invalid: ($v.paySum.$error  && submited)}]">
          <vue-slider v-model.lazy="paySum"
                      tooltip="none"
                      :disabled="dataOtpFlags.confirmOtpStatus"
                      :min="Math.ceil(minValueForPaySum)"
                      :max="Math.floor(maxValueForPaySum)"
                      :interval="calculateInterval(Math.ceil(minDiscountPrice), Math.floor(maxValueForPaySum))">
          </vue-slider>
          <small>Не менше нетто-платежу та не більше повної ціни полісу.</small>
          <small class="error" v-if="!$v.paySum.min && submited">Cума повинна бути більше {{minValueForPaySum}} грн</small>
          <small class="error" v-else-if="!$v.paySum.max && submited">Cума повинна бути меньше {{maxValueForPaySum}} грн</small>
          <small class="error" v-else-if="$v.paySum.$dirty && !$v.paySum.required && submited">Введіть бажану суму</small>
          <small class="error" v-else-if="(($v.paySum.$dirty && !$v.paySum.minLength) || ($v.paySum.$dirty && !$v.paySum.maxLength)) && submited">Введіть коректну суму</small>
        </div>
        <button
            :readonly="!isValidPaySum || setDiscountedPriceStatus || dataOtpFlags.confirmOtpStatus" :disabled="!isValidPaySum || setDiscountedPriceStatus || dataOtpFlags.confirmOtpStatus"
            type="button"
            v-on:click="formSetDiscount()"
            class="btn btn-blue">
          Застосувати
        </button>
      </div>
    </div>
  </div>
</template>



<script>

import VueSlider from 'vue-slider-component'
import {required} from "vuelidate/lib/validators";
export default {
  props: {
    dataConfirmContractStatus: Boolean,
    dataSetDiscountedPriceStatus: Boolean,
    dataFullInfo: Object,
    dataOtpFlags: Object,
  },
  components: {VueSlider},
  data() {
    return {
      paySum: null,
      submited: null,
      setDiscountedPriceStatus: false,
    };
  },
  validations: {
    paySum:{
      min: function(value) {
        if (!value) return true;
        return value >= this.minDiscountPrice;
      },
      max: function(value) {
        if (!value) return true;
        return value <= this.maxValueForPaySum;
      }
    },
  },
  created(){
    this.setDiscountedPriceStatus = this.dataSetDiscountedPriceStatus || false;
  },
  mounted() {
    this.paySum = this.fullInfo.dynamicDiscountPrice || Math.floor(this.maxValueForPaySum);
  },
  computed:{
    fullInfo() {
      return this.dataFullInfo;
    },
    isValidPaySum() {
      if (!this.paySum) return true;
      return this.paySum >= this.minValueForPaySum && this.paySum <= this.maxValueForPaySum;
    },
    minDiscountPrice() {
      if (this.fullInfo && this.fullInfo.earnings) {
        const discount = this.fullInfo.discountPrice > 0
            ? this.fullInfo.discountPrice
            : this.fullInfo.price;
        return Math.max(1, (discount - this.fullInfo.earnings));
      }
      return 1;
    },
    minValueForPaySum() {
      return this.minDiscountPrice || 1;  // Если minDiscountPrice не определен, ставим 1
    },
    maxValueForPaySum() {
      return Number(this.fullInfo?.price) || 100000;  // Если offer.price не определен, ставим 100000
    }
  },
  watch:{
    paySum(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setDiscountedPriceStatus = false;
        this.$emit('update:priceChanged');
      }
    }
  },
  methods: {
    calculateInterval(min, max) {
      if (!min || min >= max) return 10;
      let difference = max - min;
      let interval = 10;

      while (difference % interval !== 0) {
        interval--;
        if (interval <= 1) break;
      }
      return interval;
    },
    formSetDiscount() {
      this.submited = true;
      this.setDiscountedPriceStatus = true;
      if(this.paySum == this.maxValueForPaySum || !this.paySum || isNaN(this.paySum)){
        this.$nextTick(() => {
          this.$emit('update:tooltip', { ...this.fullInfo, discountPrice: 0, discountPercent:0, discountedSum:0, earnings: 0  })
        });
      }else {
        let discountPercent = (this.fullInfo.price ? (100 - (this.paySum * 100 / this.fullInfo.price)) : 0).toFixed(2);
        let discountedSum = this.fullInfo.price - this.paySum;
        let kvp = ((this.fullInfo.earnings / this.fullInfo.price) * 100).toFixed(2);
        let kv = ((this.fullInfo.price * kvp) / 100).toFixed(2);

        let kvd = (kv - discountedSum).toFixed(2);
        let kvdp = (kvd / this.fullInfo.price * 100).toFixed(2);

        this.$nextTick(() => {
          this.$emit('update:tooltip', { ...this.fullInfo, discountPrice: this.paySum, discountPercent:discountPercent, discountedSum:discountedSum, earnings: kvd  });
        });
      }
    },
  }
}
</script>
<style>
span.red{
  color: red;
  font-weight: bold;
}
.discount-info-block{
  background: #F6F7FB;
  padding: 30px 30px;
}
@media(max-width: 991px){
  .discount-info-block{
    margin: 10px 0;
    padding: 4px 30px;
  }
}
</style>
