<template>
    <main class="order">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="navsteps">
                        <div class="navsteps__item">
                            <div class="number">1</div>
                            <div class="descr">Розрахунок</div>
                        </div>
                        <div class="navsteps__item">
                            <div class="number">2</div>
                            <div class="descr">Вибір СК</div>
                        </div>
                        <div class="navsteps__item active">
                            <div class="number">3</div>
                            <div class="descr">Оформлення</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="orders osago">
            <div class="container">
                <div class="row flex-lg-row-reverse">
                    <div class="col-lg-4">
                        <div class="warranty">
                            <h2>Ми гарантуємо</h2>
                            <ul>
                                <li class="accurate-price">Точні та вигідні ціни</li>
                                <li class="original-insurance">Оригінальну страховку</li>
                                <li class="another-solution">Рішення будь-якого питання</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-8">
                        <router-link v-if="! confirmContractStatus" to="/order-step" class="go-back">
                            Повернутися до попереднього кроку
                        </router-link>
                        <h1>Перевірте дані та перейдіть до оформлення:</h1>
                        <div class="row">
                            <div class="col-lg-6">
                                <div class="info-block">
                                    <b>Франшиза</b>
                                    <p>{{ fullInfo.franchise }} грн</p>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="info-block">
                                    <b>Номер авто</b>
                                    <p>{{ fullInfo.car.number }}</p>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="info-block">
                                    <b>Ціна</b>
                                    <p>{{ this.allPrice }} грн</p>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="info-block">
                                    <b>Номер кузова</b>
                                    <p>{{ fullInfo.car.vin }}</p>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="info-block">
                                    <b>Марка і модель</b>
                                    <p>
                                        {{ fullInfo.car.brand }} {{ fullInfo.car.model }},
                                        {{ fullInfo.car.year }},
                                    </p>
                                    <p>
                                        {{ fullInfo.car.vin }}
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-6" v-if=" orderData.requestData.otkDate">
                                <div class="info-block">
                                    <b>Дата наступного ОТК</b>
                                    <p>
                                        {{ showOtkDate(orderData.requestData.otkDate) }}
                                    </p>
                                </div>
                            </div>
                            <div class="col-12">
                                <hr/>
                            </div>
                            <div class="col-lg-6">
                                <div class="info-block">
                                    <b v-if="isLegalType">Компанія</b>
                                    <b v-else>Повне ім'я</b>
                                    <p>
                                        {{ fullInfo.customer.surname }}
                                        {{ fullInfo.customer.name }}
                                        {{ fullInfo.customer.patronymic }}
                                    </p>
                                </div>
                            </div>
                            <div v-if="! isLegalType" class="col-lg-6">
                                <div class="info-block">
                                    <b>Дата народження</b>
                                    <p>{{ showDateBirthday() }}</p>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="info-block">
                                    <b>Email, Телефон</b>
                                    <p>
                                        {{ fullInfo.customer.email }}, <br/>{{
                                           fullInfo.customer.phone
                                        }}
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="info-block">
                                    <b>{{ isLegalType ? 'ЄДРПОУ' : 'ІПН' }}</b>
                                    <p>{{ fullInfo.customer.identificationCode }}</p>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="info-block">
                                    <b>Адреса</b>
                                    <p>
                                        {{ fullInfo.customer.address.street }}
                                        {{ fullInfo.customer.address.house }},
                                        {{ fullInfo.customer.address.number }}
                                    </p>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="info-block">
                                    <b>Прописка</b>
                                    <p>{{ showCityName() }}</p>
                                </div>
                            </div>
                            <div class="col-12">
                                <hr/>
                            </div>
                            <div v-if="! isLegalType" class="col-lg-6">
                                <div class="info-block">
                                    <b>Серія і номер</b>
                                    <p>
                                        {{ showDocumentType() }}, {{
                                            !this.customerHaveDocumentERetiree() ? fullInfo.customer.documentation.serial : ''
                                        }}
                                        {{ fullInfo.customer.documentation.number }}
                                    </p>
                                </div>
                            </div>
                            <div v-if="! isLegalType" class="col-lg-6">
                                <div class="info-block">
                                    <b>Видано</b>
                                    <p>{{ fullInfo.customer.documentation.issuedBy }}</p>
                                </div>
                            </div>
                            <div v-if="! isLegalType" class="col-lg-6">
                                <div class="info-block">
                                    <b v-if="this.customerHaveDocumentERetiree()">Закінчення дії</b>
                                    <b v-else>Дата видачі</b>
                                    <p>{{
                                          this.customerHaveDocumentERetiree() ? fullInfo.customer.documentation.endDateOfIssue : fullInfo.customer.documentation.dateOfIssue
                                        }}</p>
                                </div>
                            </div>
                            <div v-if="! isLegalType && fullInfo.customer.documentation.endDateOfIssue"
                                 class="col-lg-6">
                                <div class="info-block">
                                    <b>Закінчення дії</b>
                                    <p>{{ fullInfo.customer.documentation.endDateOfIssue }}</p>
                                </div>
                            </div>
                            <div v-if="showTypeRegistration()" class="col-12">
                                <hr/>
                            </div>
                            <div v-if="orderData.requestData.otkDate" class="col-lg-6">
                                <div class="info-block">
                                    <b>Дата ОТК</b>
                                    <p>
                                        {{ showOtkDate(orderData.requestData.otkDate) }}
                                    </p>
                                </div>
                            </div>
                            <div v-if="showTypeRegistration()" class="col-lg-6">
                                <div class="info-block">
                                    <b>Тип реєстрації</b>
                                    <p>
                                        {{ showTypeRegistration() }}
                                    </p>
                                </div>
                            </div>
                            <div v-if="! isLegalType" class="col-lg-6">
                                <div class="info-block">
                                    <b>Період</b>
                                    <p>{{ dateInfo.period }}</p>
                                </div>
                            </div>
                            <div class="col-lg-6">
                                <div class="info-block">
                                    <b>Дата початку</b>
                                    <button v-if="! confirmContractStatus" @click="changestartOrderDate()"
                                            v-b-tooltip="'Змінити дату початку'"
                                            class="history-order__form-btn edit-limit">
                                        <svg width="16" height="20" viewBox="0 0 16 20" fill="none"
                                             xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M15.045 5.1023C15.423 4.71657 15.631 4.2043 15.631 3.65937C15.631 3.11444 15.423 2.60217 15.045 2.21644L13.459 0.59799C13.081 0.212256 12.579 0 12.045 0C11.511 0 11.009 0.212256 10.632 0.596969L0 11.4128V15.9182H4.413L15.045 5.1023ZM12.045 2.04092L13.632 3.65835L12.042 5.27476L10.456 3.65733L12.045 2.04092ZM2 13.8772V12.2598L9.04 5.09822L10.626 6.71667L3.587 13.8772H2ZM0 17.9591H16V20H0V17.9591Z"
                                                fill="#007BBB"/>
                                        </svg>
                                    </button>
                                    <date-picker v-model="startOrderDate" :config="optionsStartOrderDate"
                                                 :class="['form-control','date-input']" placeholder="Введіть дату"
                                                 :disabled="startOrderDateDisabled"></date-picker>
                                </div>
                            </div>
                            <div v-if="! isLegalType" class="col-lg-6">
                                <div class="info-block">
                                    <b>Дата закінчення</b>
                                    <p>{{ finish_at }}</p>
                                </div>
                            </div>
                            <div v-if="! isLegalType" class="col-12">
                                <hr/>
                            </div>

                            <div class="col-12 order-dgo">
                                <div class="row align-items-center order-dgo-wrapp"
                                     v-if="orderData.offer.listDgo && orderData.offer.listDgo.length !== 0">
                                    <div class="col-md-6">
                                        <div class="order-dgo__descr">
                                            <img src="../../public/images/ukasko-dgo-logo.svg" alt="">
                                            <div>
                                                <span>Додаткове покриття</span>
                                                <p>Рекомендуємо розширити ліміти</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <select :disabled="confirmContractStatus" v-model="dgoCost"
                                                @change="selectDgo($event, orderData.offer)" id="list_dgo"
                                                name="dgo_price" class="custom-select">
                                            <option value="0">Без доп покриття</option>
                                            <option :value="parseInt(dgo.cost)" :id-dgo="dgo.id"
                                                    :coverage="dgo.coverage" :company_id="dgo.company_id"
                                                    v-for="(dgo) in orderData.offer.listDgo">+
                                                {{ dgo.coverage | toNumerals }} грн (за {{ dgo.cost | toNumerals }} грн)
                                            </option>
                                        </select>
                                        <div class="order-dgo-hint">
                                            <span>Що таке додаткове покриття?</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-12 order-dgo">
                                <div class="row align-items-center order-dgo-wrapp"
                                     v-if="orderData.offer.listAutolawyer && orderData.offer.listAutolawyer.length !== 0">
                                    <div class="col-md-6">
                                        <div class="order-dgo__descr">
                                            <img src="../../public/images/ukasko-dgo-logo.svg" alt="">
                                            <div>
                                                <span>Автоюрист</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-6">
                                        <select :disabled="confirmContractStatus" v-model="autolawyerPrice"
                                                @change="selectAutolawyer($event, orderData.offer)" id="list_autolawyer"
                                                name="autolawyer_price" class="custom-select">
                                            <option value="0">Без доп покриття</option>
                                            <option :value="autolawyer.price" :id-autolawyer="autolawyer.id"
                                                    :coverage="autolawyer.coverage" :company_id="autolawyer.company_id"
                                                    v-for="(autolawyer) in orderData.offer.listAutolawyer">+
                                                "{{ autolawyer | toAutolawyerName }}" (за
                                                {{ autolawyer.price | toNumerals }} грн)
                                            </option>
                                        </select>
                                        <div class="order-dgo-hint">
                                            <span>Що таке додаткове покриття?</span>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-3">
                                <div class="info-block"><b>Загальна вартість :</b></div>
                            </div>
                            <div class="col-lg-3  param-price">
                              <div class="result__price">
                                <div v-if="this.fullInfo.dynamicDiscountedSum > 0 || this.fullInfo.discountPrice > 0" class="old"
                                     data-placement="bottom" data-toggle="tooltip"
                                     title="Знижка надається безпосередньо за наш рахунок від ціни, що надається страховою компанією. Ми забезпечуємо найнижчу ціну на ринку. Вартість поліса вказана з урахуванням знижки.">
                                  <span>{{ this.allPrice }} <span>грн</span></span>
                                  <b v-if="this.fullInfo.dynamicDiscountedSum > 0">-{{ this.fullInfo.dynamicDiscountedSum }} грн</b>
                                  <b v-else >-{{ this.fullInfo.discountPercent }}%</b>
                                </div>

                                <div data-toggle="tooltip" data-html="true"  data-id="details-discount-tooltip" :title="showPriceDetails" v-if="this.fullInfo.dynamicDiscountedSum > 0 || this.fullInfo.discountPrice > 0" :class="['price','value','red']">
                                  {{ showFinishPrice }} <span>грн</span>
                                </div>
                                <div v-else :class="['value']" title=""  data-original-title="">
                                  {{ showFinishPrice }} <span>грн</span>
                                </div>
                              </div>

                            </div>
                            <use-bonus
                                class="col-lg-6"
                                @show-earnings="showEarnings = $event"
                                @use-bonuses="useBonuses = $event"
                            ></use-bonus>
                        </div>
                        <earnings v-show="earnings && showEarnings"
                                  :earnings="earnings"
                                  :dynamic-earnings="fullInfo.dynamicEarnings"
                                  :use-bonuses="useBonuses"
                                  :data-is-load-earning="dataIsLoadEarning"
                                  v-model="bonusSystemValue"
                        ></earnings>
                        <div class="row">
                            <div class="col-12">
                                <hr/>
                            </div>
                            <div :class="[(confirmContractStatus ? 'col-lg-12' : 'col-lg-6') ,'text-center','position-relative']">
                                <button
                                    type="button"
                                    v-on:click="draft()"
                                    class="btn btn-dark">
                                    Переглянути чернетку
                                </button>
                            </div>
                            <div v-if="!confirmContractStatus" class="col-lg-6 text-center position-relative">
                                <router-link to="/order-step">
                                    <button
                                        type="button"
                                        class="btn btn-warning">
                                        Редагувати дані
                                    </button>
                                </router-link>
                            </div>
                            <div class="col-12">
                                <hr/>
                            </div>
                            <dynamic-discount-block
                                :data-confirm-contract-status="confirmContractStatus"
                                :data-set-discounted-price-status="setDiscountedPriceStatus"
                                :data-full-info="fullInfo"
                                :data-otp-flags="otpFlags"
                                @update:tooltip="updateTooltip"
                                @update:priceChanged="discountPriceChanged"
                            ></dynamic-discount-block>

                            <otp-block
                                ref="otpBlock"
                                :data-otp-flags="otpFlags"
                                :data-agreement="agreement"
                                :data-confirm-contract-status="confirmContractStatus"
                                @formSendOtp="formSendOtp"
                                @otpCheck="otpCheck"
                                @otpChanged="otpChanged"
                            ></otp-block>

                            <div v-if="confirmContractStatus && setDiscountedPriceStatus  && otpFlags.confirmOtpStatus" class="col-lg-12 text-center position-relative">
                                <button
                                    v-if="orderPaid"
                                    class="btn btn-success"
                                >
                                    Сплачено
                                </button>
                                <button
                                    v-else
                                    type="button"
                                    @click="showAllOrderPaymentWays()"
                                    class="btn btn-warning">
                                    Сплатити поліс
                                </button>
                            </div>
                            <div v-if="showFileInputReceipt" class="col-12 text-center">
                                <file-input :http-request="uploadReceipt" @remove-file="receiptLoaded = false">Прикріпити файл квитанції</file-input>
                            </div>
                            <div v-if="confirmContractStatus && setDiscountedPriceStatus  && otpFlags.confirmOtpStatus" class="col-12">
                              <hr/>
                            </div>
                            <div v-if="! confirmContractStatus" class="col-12">
                              <div class="form-check">
                                <input
                                    type="checkbox"
                                    name="agreement"
                                    id="agreement"
                                    :class="[
                        'form-check-input',
                        { 'is-invalid': agreement == false },
                      ]"
                                    v-model="agreement"
                                />
                                <label for="agreement" class="form-check-label"
                                >Згоден з обробкою персональних даних. Дані будуть
                                  передані в страхову компанію для формування поліса</label>
                                <small v-if="agreement == false"> Угода з обробкою персональних даних є
                                  обов'язковою!</small
                                >
                              </div>
                            </div>
                            <div v-if="! confirmContractStatus" class="col-12 text-center position-relative">
                                <button :disabled="!setDiscountedPriceStatus"
                                        type="button"
                                        v-loading="loadingOrder"
                                        v-on:click="makeContract()"
                                        class="btn btn-blue">Заявити
                                </button>
                            </div>
                            <div v-else class="col-12 text-center position-relative">
                                <button
                                    :disabled="!canConfirmContract"
                                    type="button"
                                    @click="confirmContract()"
                                    :class="['btn', (canConfirmContract ? 'btn-blue' : 'btn-dark-grey')]"
                                >Оформити
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <animationModal/>
        <choice-otp-sending-direction ref="ChoiceOtpSendingDirection"
                                      @sendOtpApi="sendOtpApi"></choice-otp-sending-direction>
        <modal-declared-success ref="modalDeclaredSuccess"></modal-declared-success>
        <modal-response-status :data-response="dataResponse"
                               :data-product-type="dataProductType"></modal-response-status>
        <modal-response-confirm-status :data-response="dataResponse"
                                       :data-product-type="dataProductType"></modal-response-confirm-status>
        <modal-get-invoice-order ref="modalGetInvoiceOrder"></modal-get-invoice-order>
        <modal-get-instant-loan ref="modalGetInstantLoan"></modal-get-instant-loan>
        <modal-get-mono-pay-parts ref="showModalGetMonoPayParts"></modal-get-mono-pay-parts>
        <modal-get-all-payment-ways
            ref="modalGetAllPaymentWays"
            @showModalGetInvoiceOrder="showModalGetInvoiceOrder"
            @showModalGetInstantLoan="showModalGetInstantLoan"
            @showModalGetMonoPayParts="showModalGetMonoPayParts"
            @showModalGetInvoiceLegal="showModalGetInvoiceLegal"
            :is-legal-type="isLegalType"
        >
        </modal-get-all-payment-ways>
        <fin-monitoring :visible.sync="showFinMonitoringModal" @confirm="confirmFinMonitoring"></fin-monitoring>
    </main>
</template>

<script>
import animationModal from "@/components/app/modals/modal-payAnimation";
import {
    downloadInvoiceLegal,
    getPaymentInvoice,
    showOrder,
    sendOtp,
    checkOtp,
    saveOrder
} from "@/api/order";
import {
    DeclaredSuccess as ModalDeclaredSuccess,
    GetPaymentLink as ModalGetInvoiceOrder,
    GetInstantLoan as ModalGetInstantLoan,
    GetMonoPayParts as ModalGetMonoPayParts,
    GetAllPaymentWays as ModalGetAllPaymentWays,
    ChoiceOtpSendingDirection
} from "@/components/app/modals/order";
import {customerMixin, companyMixin, otpFunc} from "../mixin";
import modalResponseStatus from '@/components/app/modals/modal-response-status.vue';
import modalResponseConfirmStatus from '@/components/app/modals/modal-response-confirm-status.vue';
import otpBlock from '@/components/app/otp/otpBlock.vue';
import dynamicDiscountBlock from '@/components/app/dynamic-discount/dynamicDiscountBlock.vue';
import Earnings from "@/components/app/bonusSystem/Earnings.vue";
import {calculate} from "@/api/offer";
import UseBonus from "@/components/app/bonusSystem/UseBonus.vue";
import FinMonitoring from "@/components/app/modals/order/FinMonitoring.vue";
import FileInput from "@/components/app/forms/FileInput.vue";
import {checkPayment, uploadReceipt} from "@/api/invoice";

export default {
    components: {
        FileInput,
        FinMonitoring,
        Earnings,
        ChoiceOtpSendingDirection,
        otpBlock,
        UseBonus,
        animationModal,
        ModalDeclaredSuccess,
        ModalGetInvoiceOrder,
        ModalGetInstantLoan,
        ModalGetMonoPayParts,
        ModalGetAllPaymentWays,
        modalResponseStatus,
        modalResponseConfirmStatus,
        dynamicDiscountBlock,
    },

    mixins: [customerMixin, companyMixin, otpFunc],
    data: function () {
        return {
            isAgreeFinMonitoring:false,
            loadingOrder: false,
            showFinMonitoringModal: false,
            otpFlags: {
                otpPass: "", // ОТР - код
                otpStatus: true, // true - Включить отправку ОТР - кода
                confirmOtpStatus: false, // true - OTP код проверен успешно
                sentOtpPass: false, // true - OTP код был отправлен mail/viber/sms
                sendingOtpError: false, // true - при отправке ОТР кода произошла ошибка
                submitOtpCheck: false, // true - была нажата кнопка отправки ОТР кода на проверку
                otpCodeValidate: true, // статус проверки ОТР кода true - прошел проверку, false - код неверный
                pandingOtpSending: false, // флаг для отображения прелоадера при отправке ОТР кода
                pandingOtpCheck: false, // флаг для отображения прелоадера при отправке ОТР кода на проверку
            },
            showEarnings: false,
            dataIsLoadEarning: false,
            earnings: 0,
            useBonuses: false,
            bonusSystemValue: 0,
            finish_at: this.$store.state.osagoStore.dateInfo.endDate,
            startOrderDateDisabled: true,
            startOrderDate: new Date(moment(this.$store.state.osagoStore.dateInfo.startDate, 'DD.MM.YYYY').toDate()),
            optionsStartOrderDate: {
                format: 'DD.MM.YYYY',
                useCurrent: false,
                minDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 1),
                maxDate: new Date(new Date().getFullYear(), new Date().getMonth(), (new Date().getDate() + (this.$store.state.osagoStore.infoOrder.offer.dateFromMax ?? this.$store.state.osagoStore.fullInfoOrder.dateFromMax))),
                locale: 'uk',
                icons: {
                    previous: 'icon arrow-point-to-left',
                    next: 'icon arrow-point-to-right',
                },
            },
            //allPrice: null,
            finishPrice: null,
            dgoCost: null,
            autolawyerPrice: null,
            endDate: null,
            dataResponse: null,
            dataProductType: 'osago',
            invoiceLink: null,
            isLegalType: false,
            agreement: null,
            confirmContractStatus: false,
            setDiscountedPriceStatus: false,
            orderId: null,
            optionsStarDate: {
                format: 'DD.MM.YYYY',
                useCurrent: false,
                minDate: new Date(new Date().getFullYear(), new Date().getMonth(), (new Date().getDate() + 1)),
                locale: 'uk',
                icons: {
                    previous: 'icon arrow-point-to-left',
                    next: 'icon arrow-point-to-right',
                },
            },
            monthList: [{'id': 1}, {'id': 2}, {'id': 3}, {'id': 4}, {'id': 5}, {'id': 6}, {'id': 7}, {'id': 8}, {'id': 9}, {'id': 10}, {'id': 11}, {'id': 12}],
            months: [],
            months2: [],

            //TODO need refactoring fetch from server...
            order: null,
            receiptLoaded: false,
        };
    },
    async created() {
        await this.$store.dispatch('initOsagoFullInfoOrder');
        await this.$store.dispatch('initOsagoInfoOrder');

        this.isLegalType = (this.orderData.requestData?.customerType == 2);
        this.dgoCost = this.fullInfo?.dgo?.price ? parseInt(this.fullInfo?.dgo?.price) : 0;
        this.autolawyerPrice = this.orderData.offer?.autolawyerPrice ? parseInt(this.orderData.offer?.autolawyerPrice) : 0;

        this.confirmContractStatus = this.fullInfo?.confirmContractStatus || false;
        if(this.confirmContractStatus){
          this.setDiscountedPriceStatus = true;
          this.agreement = true;
        }
        this.orderId = this.fullInfo.orderId;

        this.invoiceLink = this.fullInfo.invoiceLink;
        if(this.fullInfo.otpFlags){
          this.otpFlags = this.fullInfo.otpFlags;
        }

        if (this.orderData?.requestData?.endDate) {
            this.endDate = moment.unix(this.orderData?.requestData?.endDate).format("DD.MM.YYYY");
            this.$set(this.optionsStarDate, 'maxDate', new Date(moment.unix(this.orderData?.requestData?.endDate).subtract(1, 'days').format("YYYY.MM.DD")));
        } else {
            this.$set(this.optionsStarDate, 'maxDate', new Date(new Date().getFullYear(), new Date().getMonth(), (new Date().getDate() + this.getMaxDays())));
        }

        this.order = await this.loadOrder();
        //TODO need refactoring otp code
        this.checkOtpEntered();

        if (this.$route.query.invoice) {
            this.checkPaid(this.$route.query.invoice);
        } else if (this.order.payments) {
            const payments = this.order.payments.find(item => item.invoice.type_id === 2)

            if (payments) {
                this.checkPaid(payments.invoice.id)
            }
        }
    },
    watch: {
        'otpFlags': {
          handler: function(newVal, oldVal) {
            let fullInfo = Object.assign({}, this.fullInfo);
            fullInfo.otpFlags = newVal;
            this.$store.commit('SET_FULL_INFO_ORDER', fullInfo);
          },
          deep: true,
        },
        'fullInfo': {
          handler: function(newVal, oldVal) {
            this.$store.commit('SET_FULL_INFO_ORDER', this.fullInfo);
          },
          deep: true,
        },
        startOrderDate: function (newValue) {
            this.getContractPeriod()
        },
    },
    computed: {
        allPrice: function(){
          return Number( this.fullInfo.price) + Number(this.dgoCost || 0) + Number(this.autolawyerPrice || 0);
        },
        //TODO не оптимальный вариант как и все эти три ужасныйх шана. Когда то они будут переписаны на отлиный вариант Vuejs
        fullInfo() {
            return this.$store.state.osagoStore.fullInfoOrder;
        },
        orderData() {
            var usageMonthsDec = this.$store.state.osagoStore.infoOrder.offer.usageMonths;
            if (usageMonthsDec == '')
                var usageMonthsDecLen = 0;
            else {
                var usageMonthsDecUn = (usageMonthsDec >>> 0).toString(2);
                var usageMonthsDecLen = usageMonthsDecUn.length;
            }
            var usageMonthsTemp = '';
            if (usageMonthsDecLen == 0)
                usageMonthsTemp = '';
            else {
                if (usageMonthsDecLen < 12) {
                    for (var i = 12 - usageMonthsDecLen; i > 0; i--) {
                        usageMonthsTemp = usageMonthsTemp + '0,';
                    }
                }
                for (var i = 0; i < usageMonthsDecLen; i++) {
                    usageMonthsTemp = usageMonthsTemp + usageMonthsDecUn.substr(i, 1);
                    if ((i + 1) != usageMonthsDecLen)
                        usageMonthsTemp = usageMonthsTemp + ",";
                }
                usageMonthsTemp = usageMonthsTemp.split(",");
            }
            if (usageMonthsTemp != '') {
                if (Array.isArray(usageMonthsTemp)) {
                    if (usageMonthsTemp.length > 0) {
                        usageMonthsTemp.forEach((item, key) => {

                            if (item != 0) {
                                this.months2.push({id: (key + 1)});
                            }
                        });
                    }
                }
            }
            this.months = this.months2;
            return this.$store.state.osagoStore.infoOrder;
        },
        showFinishPrice() {
            return this.showPrice(this.allPrice, this.dgoCost, this.autolawyerPrice);
        },
        showPriceDetails(){
            let text = "";
            if(this.fullInfo.dynamicDiscountPrice > 0) {
              text =
                  "<div class='details-price-tooltip'> " +
                  "<div class='details-price'>" +
                  "      <div class=\"tooltip-row\">" +
                  "        <span class=\"tooltip-label\">Знижка:</span>" +
                  "        <span class=\"tooltip-value\">"+ (this.fullInfo.dynamicDiscountedSum || ' - ') + " грн</span>" +
                  "      </div>" +
                  "      <div class=\"tooltip-row\">" +
                  "        <span class=\"tooltip-label\">Ціна зі знижкою:</span>" +
                  "        <span class=\"tooltip-value\">" + (this.fullInfo.dynamicDiscountPrice || ' - ')+ " грн</span>" +
                  "      </div>" +
                  "      <div class=\"tooltip-row\">" +
                  "        <span class=\"tooltip-label\">Загальний заробіток:</span>" +
                  "        <span class=\"tooltip-value\">" + (this.fullInfo.earnings || ' - ') + " грн</span>" +
                  "      </div>" ;
              if(this.fullInfo.dynamicDiscountPrice > 0){
                text +=  "<div class=\"tooltip-row\">" +
                    "        <span class=\"tooltip-label\">Заробіток зі знижкою:</span>" +
                    "        <span class=\"tooltip-value\">" + (this.fullInfo.dynamicEarnings || ' - ') + " грн</span>" +
                    "      </div>" ;
              }
              text += "</div> "
              "</div>";
            }
            return text;
        },
        dateInfo() {
            return this.$store.state.osagoStore.dateInfo;
        },
        getParentUserId() {
            return this.$store.getters.USER_INFO.parentId ?? this.$store.getters.USER.id;
        },
        canConfirmContract() {
            return this.confirmContractStatus && ((this.otpFlags.confirmOtpStatus && this.otpFlags.otpStatus) || !this.otpFlags.otpStatus) && (this.orderPaid || this.receiptLoaded)
        },
        orderPaid() {
            if (this.order && this.order.payments.length) {
                const sumPay = this.order.payments.reduce((sumPay, item) => {
                    if (item.invoice.status_id === 2) {
                        return sumPay + parseFloat(item.invoice.amount);
                    }
                    return sumPay;
                }, 0,);

                return sumPay >= this.order.allPrice;
            }

            return false;
        },
        showFileInputReceipt() {
            return this.confirmContractStatus && this.otpFlags.confirmOtpStatus && this.order?.payments.length && this.order?.payments.some(item => item.invoice.type_id === 8)
        }
    },

    filters: {
        // toPercent: function (item) {
        //     return Math.round(item.discount / (item.discount + item.price) * 100);
        // },
        toFixed: function (value, precision) {
            return value.toFixed(precision || 2);
        },
        toNumerals: function (value) {
            let newValue = parseInt(value);
            if (isNaN(newValue)) {
                return value;
            }
            return newValue.toLocaleString();
        },
        toAutolawyerName: function (item) {
            switch (item.program) {
                case 2:
                    return "Комфорт";
                case 3:
                    return "Комфорт плюс";
                default:
                    return "Стандарт";
            }
        },
    },

    methods: {
        showNetto(offer){
          let earnings = this.showEarning(offer);
          let price = (offer.dynamicDiscountPrice > 0 || offer.discountPrice > 0) ? this.showDiscountPrice(offer) : this.showPrice(offer);
          return Number(price) - Number(earnings);
        },
        showEarning(offer){
          return Number((offer.dynamicDiscountPrice > 0) ? offer.dynamicEarnings : offer.earnings);
        },
        /*showPrice(offer){
          return Number(offer.price) ;
        },*/
        showPrice(price, dgoCost, autolawyerPrice) {
          price = this.fullInfo.dynamicDiscountPrice || this.fullInfo.discountPrice || price;
          return (Number(price) + Number(dgoCost || 0) + Number(autolawyerPrice || 0)).toFixed(2);
        },
        showDiscountPrice(offer){
          return Math.round(Number(offer.price - Number(offer.dynamicDiscountedSum || 0)));
        },
        discountPriceChanged() {
          this.setDiscountedPriceStatus = this.confirmContractStatus || false;
        },
        updateTooltip(data) {
          this.dataIsLoadEarning = true;
          let fullInfo = Object.assign({}, this.fullInfo);

          const discountDetails = {
            dynamicDiscountPrice: 0,
            dynamicDiscountPercent: 0,
            dynamicDiscountedSum: 0,
            dynamicEarnings: 0,
          };
          if (parseFloat(data.price) > parseFloat(data.discountPrice)) {
            discountDetails.dynamicDiscountPrice = +parseFloat(data.discountPrice).toFixed(2)
            discountDetails.dynamicDiscountPercent = +parseFloat(data.discountPercent).toFixed(2);
            discountDetails.dynamicDiscountedSum = +parseFloat(data.discountedSum).toFixed(2);
            discountDetails.dynamicEarnings = +parseFloat(data.earnings).toFixed(2);
            discountDetails.earnings = +parseFloat(fullInfo.earnings).toFixed(2);
          }
          fullInfo.discountDetails = discountDetails;
          fullInfo = {...fullInfo, ...discountDetails};
          this.$store.commit('SET_FULL_INFO_ORDER', fullInfo);


          let autolawyerEarning = 0;
          if(this.hasAutolawyer()){
            autolawyerEarning = this.fullInfo.autolawyer.earning;
          }
          //this.earnings = (this.fullInfo?.dynamicEarnings || this.fullInfo.earnings) + dgoEarning + autolawyerEarning;

          this.loadingOrder = true;

          this.$nextTick(() => {
            $('[data-id="details-discount-tooltip"]').tooltip('dispose').tooltip();
          });

          this.dataIsLoadEarning = false;

          let orderData = this.makeOrderData();

           orderData.statusId = 1;

           saveOrder(orderData).then(response => {
            this.setDiscountedPriceStatus = true;
            this.loadingOrder = false;
          });

        },
        uploadReceipt(event) {
            const payment = this.order.payments.find(item => item.invoice.type_id === 8)
            if (payment) {
                uploadReceipt(payment.invoice.id, event.file).then(response => {
                    if (response.data.status === 'success') {
                        this.receiptLoaded = true;
                    }
                })
            }
        },
        async loadOrder() {
            let response = await showOrder(this.fullInfo.orderId)
            return response.data.data;
        },
        checkPaid(invoiceId) {
            checkPayment(invoiceId).then(response => {
                if (response.data.data.status_id === 2) {
                    this.loadOrder().then(response => this.order = response);
                }
            });
        },
        confirmFinMonitoring() {
            setTimeout(() => {
                this.isAgreeFinMonitoring = true;
                this.makeContract('make');
            }, 1500);
        },
        checkFinMonitoring() {
            if (this.allPrice >= 40000 && !this.isAgreeFinMonitoring) {
                this.showFinMonitoringModal = true;

                return true;
            }

            return false;
        },
        showDateBirthday() {
            if (/^\d{2}\.\d{2}\.\d{4}$/.test(this.fullInfo.customer.dateBirth)) {
                return this.fullInfo.customer.dateBirth;
            } else {
                return moment.unix(this.fullInfo.customer.dateBirth).format('DD.MM.YYYY');
            }
        },
        showCityName() {
            if (this.fullInfo.customer.address.cityObject && this.fullInfo.customer.address.cityObject.name_full_name_ua) {
                return this.fullInfo.customer.address.cityObject.name_full_name_ua;
            } else if (this.fullInfo.customer.address.cityName) {
                return this.fullInfo.customer.address.cityName;
            } else {
                return "";
            }
        },
        changestartOrderDate() {
            this.startOrderDateDisabled = this.confirmContractStatus ? true : false;
        },
        getContractPeriod() {
            let storDateInfo = this.$store.state.osagoStore.dateInfo;
            if (storDateInfo.period_id == 15) {
                this.finish_at = moment(this.startOrderDate, "DD.MM.YYYY").add(storDateInfo.period_id, 'days').subtract(1, 'days').format("DD.MM.YYYY");
            } else {
                this.finish_at = moment(this.startOrderDate, "DD.MM.YYYY").add(storDateInfo.period_id, 'months').subtract(1, 'days').format("DD.MM.YYYY");
            }

            let dateInfo = {
                startDate: this.startOrderDate,
                endDate: this.finish_at,
                period: storDateInfo.period,
                period_id: storDateInfo.period_id
            }
            this.$store.commit('SET_DATE_INFO', dateInfo);
        },

        getMaxDays() {
            return this.$_.findWhere(this.$store.getters.START_COMPANY_LIMIT, {key: this.orderData?.offer?.companyId})?.days ?? 90;
        },
        showAllOrderPaymentWays() {
            this.$refs.modalGetAllPaymentWays.open(this.orderId, this.allPrice);
        },
        showModalGetInvoiceOrder() {
            this.$refs.modalGetInvoiceOrder.open(this.orderId, this.showFinishPrice);
        },
        showModalGetInstantLoan() {
            this.$refs.modalGetInstantLoan.open(this.orderId, this.showFinishPrice, this.months);
        },
        showModalGetInvoiceLegal: async function () {
            let orderWithData = await showOrder(this.orderId);
            const mtsbuCode = orderWithData.data.data?.mtsbuCode ?? null;

            let invoiceLegalData = {
                company: this.fullInfo.customer.name,
                code: this.fullInfo.customer.identificationCode ?? '',
                price: this.showFinishPrice,
                mtsbuCode: mtsbuCode,
            }

            let response = await getPaymentInvoice(this.orderId, this.showFinishPrice, 'legal-entity-insurance', 2, this.months);

            this.order = await this.loadOrder();

            let params = new URLSearchParams(invoiceLegalData).toString()
            downloadInvoiceLegal(this.getParentUserId, this.orderId, params)
        },
        showModalGetMonoPayParts() {
            this.$refs.showModalGetMonoPayParts.open(this.orderId, this.showFinishPrice, this.months);
        },
        customerHaveDocumentERetiree() {
            return this.isDocumentERetiree(this.orderData?.requestData?.customerPrivilege);
        },
        showTypeRegistration() {
            return this.$_.findWhere(this.$store.getters.INSURANCE_REGISTRATION_TYPE, {id: this.orderData?.requestData?.registrationType})?.value;
        },
        hasAutolawyer(orderData) {
            if (this.orderData.offer.autolawyerPrice) {
                return true;
            }
            return false;
        },
        draft(type) {
            if (this.hasAutolawyer()) {
                window.open(`/api/orders/draft/${this.$store.getters.USER.id}/${this.fullInfo.orderId}?autolawyer=true`);
                window.open(`/api/orders/draft/${this.$store.getters.USER.id}/${this.fullInfo.orderId}`);
            } else {
                window.open(`/api/orders/draft/${this.$store.getters.USER.id}/${this.fullInfo.orderId}`);
            }
        },
        showDocumentType() {
            return this.$_.findWhere(this.$store.getters.DOCUMENTS_TYPES, {id: this.fullInfo?.customer?.documentation?.type})?.name;
        },
        showOtkDate(otkDate) {
            return moment.unix(otkDate).format("DD.MM.YYYY");
        },
        selectDgo($event, item) {
            let dgoData = this.$_.find(this.orderData.offer.listDgo, item => parseInt(item.cost) === this.dgoCost);
            if (dgoData) {
                dgoData.price = dgoData.cost;
                this.$set(this.fullInfo, 'dgo', dgoData);
            } else {
                this.$set(this.fullInfo, 'dgo', {});
            }
            this.recalculate()
        },
        selectAutolawyer($event, item) {
            //TODO выглядит не очень искать по цене. но так в этом компоненте всё не очень.
            let autolawyerData = this.$_.find(this.orderData.offer.listAutolawyer, item => parseInt(item.price) === this.autolawyerPrice);
            if (autolawyerData) {
                this.$set(this.fullInfo, 'autolawyer', autolawyerData);
            } else {
                this.$set(this.fullInfo, 'autolawyer', {});
            }
            this.recalculate()
        },
        makeOrderData(){

          this.orderData.requestData.franchise = parseInt(this.fullInfo.franchise);
          this.orderData.requestData.car = this.fullInfo.car;
          this.orderData.requestData.startDate = this.startOrderDate;
          this.orderData.requestData.bonusSystemValue = this.bonusSystemValue;
          this.orderData.requestData.isCarOwner = this.fullInfo.isCarOwner;
          this.fullInfo.startDate = this.startOrderDate;

          let data = JSON.parse(
              JSON.stringify(
                  Object.assign(this.fullInfo, this.orderData.requestData)
              )
          );
          //TODO скорее всего в этом смысла уже нет.
          // if (data.dgoPrice && ! isNaN(parseFloat(data.dgoPrice))) {
          //     data.price =  parseFloat(data.price) - parseFloat(data.dgoPrice);
          //     data.price = data.price.toFixed(2);

          if(this.fullInfo.dgo){
            let dgoPrice = this.fullInfo.dgo.price;
            let dgoCompensation = this.fullInfo.dgo?.compensation || this.fullInfo.dgo?.coverage;
            let dgoCompanyId = this.fullInfo.dgo.company_id;

            if(!this.fullInfo.dgo.price && this.fullInfo.dgo.cost){
              dgoPrice = this.fullInfo.dgo.cost;
              dgoCompensation = this.fullInfo.dgo?.compensation || this.fullInfo.dgo?.coverage;;
              dgoCompanyId = this.fullInfo.dgo.company_id;
            }else{
              dgoPrice = this.fullInfo.dgo.price;
              dgoCompensation = this.fullInfo.dgo?.compensation || this.fullInfo.dgo?.coverage;;
              dgoCompanyId = this.fullInfo.dgo.company_id;
            }

            data.dgo = {
              id: this.fullInfo.dgo.id,
              price: dgoPrice,
              compensation: dgoCompensation,
              company_id: dgoCompanyId,
            }
            data.dgoCoverage = dgoCompensation;
            data.dgoCompanyId = dgoCompanyId;
            data.dgoId = this.fullInfo.dgo.id;
            data.dgoPrice = dgoPrice;
          }else if (data.dgoId) {
            data.dgo = {
              id: data.dgoId,
              price: data.dgoPrice,
              compensation: data.dgoCoverage,
              company_id: data.dgoCompanyId
            }
          }

          //TODO эту логику стоит выполнять, только после того как мы точно знаем, что данные переменные есть.
          //Наверное стоит тут добавить валидацию, перед отправкой данных. Если чего то нет, отправить на страницу с данными.
          data.customer.dateBirth = moment(data.customer.dateBirth, 'DD.MM.YYYY', true).isValid()? moment(data.customer.dateBirth, "DD.MM.YYYY").utc(true).unix() : data.customer.dateBirth;

          // old
          // data.customer.documentation.dateOfIssue = moment( this.customerHaveDocumentERetiree() ? data.customer.documentation.endDateOfIssue : data.customer.documentation.dateOfIssue, "DD.MM.YYYY").
          // utc(true).unix();
          data.customer.documentation.dateOfIssue = moment( this.customerHaveDocumentERetiree() ? data.customer.documentation.endDateOfIssue : data.customer.documentation.dateOfIssue, "DD.MM.YYYY").
          utc(true).unix();
          // new
          data.customer.documentation.endDateOfIssue = data.customer.documentation.endDateOfIssue ? moment( data.customer.documentation.endDateOfIssue, "DD.MM.YYYY").
              utc(true).unix()
              : null;

          if(this.fullInfo.dynamicDiscountedSum > 0){
            data.discountDetails = {dynamicDiscountPrice:this.fullInfo.dynamicDiscountPrice,
              dynamicDiscountPercent:this.fullInfo.dynamicDiscountPercent,
              dynamicDiscountedSum:this.fullInfo.dynamicDiscountedSum,
              dynamicEarnings:this.fullInfo.dynamicEarnings,
              earnings:this.fullInfo.earnings};
          }else{
            data.discountDetails = null;
          }

          if (this.endDate) {
            data.endDate = moment(this.endDate, "DD.MM.YYYY").utc(true).unix();
          }
          if (this.finish_at) {
            data.finishAt = moment(this.finish_at, "DD.MM.YYYY").utc(true).unix();
          }

          //TODO когда-то привести это всё  в порядок.
          data.car = Object.assign({}, this.fullInfo.car);

          data.startDate = moment(this.startOrderDate, "DD.MM.YYYY").utc(true).unix();

          if(!data.offerId){
            data.offerId = this.$store.state.osagoStore.infoOrder.offer.offerId;
          }
          //delete data.startDate;
          if (this.months.length > 0) {
            //console.log('this.months',this.months);
            data.months = this.months;
            /*result = result.filter(item => {
                return this.filters.franchises.includes(item.franchise);
            });*/
          }
          return data;
        },
        makeContract() {
            if (this.checkFinMonitoring()) {
                return;
            }
            // if (this.$v.$invalid) {
            //     return false;
            // }
            this.startOrderDateDisabled = true;
            if (this.agreement) {
                this.showAnimateModal();
                let data = this.makeOrderData();

                axios.post("api/" + process.env.VUE_APP_SERVER_MODE + "/insurance/order/osago", data).then((resp) => {
                    $('#modalPayAnimation').modal('hide');
                    if (resp && resp.data.count >= 1) {
                        let fullInfo = Object.assign({}, this.fullInfo);
                        fullInfo.confirmContractStatus = true;
                        fullInfo.invoiceLink = resp.data.data[0]?.invoice_link;
                        this.confirmContractStatus = true;
                        this.orderId = resp.data.data[0].id;
                        this.$store.commit('SET_FULL_INFO_ORDER', fullInfo);
                        let mtsbuLink = resp.data.data[0]?.mtsbuLink;
                        this.invoiceLink = resp.data.data[0]?.invoice_link;

                        if (mtsbuLink) {
                            this.$refs.modalDeclaredSuccess.open(mtsbuLink);
                        } else {
                            this.$alert("ОСЦПВ заявлений", '', 'error');
                        }
                        return true;
                    } else if (resp && resp.data.message) {
                        this.dataResponse = resp.data.message;
                        $('#modalResponseStatus').modal('show');
                    } else {
                        this.$alert("ОСЦПВ не заявлений, перевірте будь ласка дані і спробуйте ще!", '', 'error', {width: '300px'});
                    }
                    $('#modalPayAnimation').modal('hide');

                }).catch(error => {
                    $('#modalPayAnimation').modal('hide');
                    this.$alert("ОСЦПВ не заявлений, перевірте будь ласка дані і спробуйте ще!", '', 'error');
                });
            } else {
                this.agreement = false;
            }
        },
        confirmContract() {
            this.showAnimateModal();
            let data = {
                orderId: this.orderId,
                bonusSystemValue: this.bonusSystemValue
            };

            if (this.orderData.offer.prolongOrderId) {
                data.prolongOrderId = this.orderData.offer.prolongOrderId;
            }

            axios.post("api/" + process.env.VUE_APP_SERVER_MODE + "/insurance/contract/confirm", data)
                .then((response) => {
                    $('#modalPayAnimation').modal('hide');

                    if (response?.data?.status === 'success') {
                        this.$alert("ОСЦПВ вдало оформлена!", '');
                        this.$router.push({name: "history"});

                        return true;
                    }

                    this.$alert("ОСЦПВ не підписаний", '', 'error');
                }).catch(error => {
                if (error?.response?.data.data.length > 0 && error?.response?.data?.data?.orderId[0]?.includes('недостатньо коштів')) {
                    this.$alert(error?.response?.data?.data?.orderId[0], '', 'error');
                } else if (error?.response?.data?.message) {
                    this.dataResponse = error?.response?.data?.message;
                    $('#modalResponseConfirmStatus').modal('show');
                } else {
                    this.$alert("ОСЦПВ не підписаний", '', 'error');
                }

                $('#modalPayAnimation').modal('hide');
            });
        },

        showAnimateModal() {
            $("#modalPayAnimation").modal({
                backdrop: "static",
                keyboard: true,
                show: true,
            });

            let i = 0;

            if (i == 0) {
                i = 1;
                let width = 1;
                let id = setInterval(frame, 200);

                function frame() {
                    if (width >= 99) {
                        clearInterval(id);
                        i = 0;
                    } else {
                        width += 2;
                        $(".results_anime_percent-text").text(`${width}%`);
                        $(".results_anime_line-scale").css({width: `${width}%`});
                    }
                }
            }
        },
        async recalculate() {
            this.dataIsLoadEarning = true;
            let requestData = this.orderData.requestData
            requestData.franchise = parseFloat(this.orderData.offer.franchise)
            requestData.months = this.months;

            let response = await calculate(requestData);
            let offer = await this.$_.find(response?.data?.data, (item) => {
                return item.company.id === this.orderData.offer.companyId && item.programmName == this.orderData.offer.programmName;
            });

            if (!!offer?.listDgo) {
                this.orderData.offer.listDgo = offer.listDgo;
            }
            if (!!offer?.listAutolawyer) {
                this.orderData.offer.listAutolawyer = offer.listAutolawyer;
            }
            let dgoEarning = 0;
            let autolawyerEarning = 0;

                if (this.fullInfo.dgo.id) {
                    let offerDgo = await this.$_.find(offer.listDgo, (item) => {
                        return parseInt(item.cost) === parseInt(this.fullInfo.dgo.cost ?? this.fullInfo.dgo?.price);
                    })
                    dgoEarning = offerDgo?.earning ?? 0;
                   // this.earnings = offer.earnings + offerDgo.earning
                } else {
                   // this.earnings = offer.earnings
                }
                if (this.fullInfo.autolawyer?.id) {
                    let offerAutolawyer = await this.$_.find(offer.listAutolawyer, (item) => {
                        return parseInt(item.price) === parseInt(this.fullInfo?.autolawyer?.price);
                    })
                    autolawyerEarning = offerAutolawyer?.earning ?? 0;
                    //this.earnings = offer.earnings + offerAutolawyer.earning
                } else {
                    //this.earnings = offer.earnings
                }
                this.earnings = (this.fullInfo?.dynamicEarnings || offer.earnings) + dgoEarning + autolawyerEarning;

                this.dataIsLoadEarning = false;
                // }
        }
    },

    async mounted() {
        await this.recalculate()
    }
};
</script>

<style scoped>
.osago {
    overflow-x: unset;
}
</style>
