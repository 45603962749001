<script>
export default {
    name: "FileInput",
    model: {
        prop: 'file',
        event: 'files-selected',
    },
    props: {
        file: {
            type: File,
            default: function () {
                return null;
            }
        },
        httpRequest: Function,
    },
    data() {
        return {
            fileList: [],
        }
    },
    methods: {
        validFile(file) {
            if (file.raw.type === 'application/pdf' || file.raw.type.startsWith("image/")) {
                return true;
            }
            this.$message.error("Файл не підтримується, тільки pdf чи зображення");

            return false
        },
        removeFile() {
            this.fileList = [];
            this.$emit("files-selected", null);
            this.$emit("remove-file");
        },
        handleChange(file, fileList) {
            if (this.validFile(file)) {
                this.fileList = fileList.slice(-1);
                this.$emit("files-selected", file.raw);
            } else {
                this.fileList = [];
            }
        },
    }
}
</script>

<template>
    <div>
        <el-upload
            class="upload-file"
            ref="upload"
            action=""
            :http-request="httpRequest"
            :file-list="fileList"
            :on-change="handleChange"
            :on-remove="removeFile"
            :auto-upload="httpRequest !== undefined"
            accept=".pdf, image/*"
        >
            <div slot="trigger" class="w-100">
                <el-button type="primary"
                           class="w-100"
                           v-if="fileList.length === 0"
                >
                    <slot>Прикріпити файл</slot>
                </el-button>
            </div>
        </el-upload>
    </div>
</template>

<style scoped>
.file-name {
    text-align: center;
    span {
        font-size: 8px;
        color: #8C8C8C;
        cursor: pointer;
    }
}
.fade-enter-active, .fade-leave-active {
    transition: opacity 1s ease;
}
.fade-enter, .fade-leave-to {
    opacity: 0;
}
.upload-file {
    width: 100%;
    display: block;
}
* >>> .el-upload--text {
    width: 100%;
}
* >>> .el-upload-list--text {
    text-align: center;
}
* >>> .el-upload {
    display: block;
}
</style>
